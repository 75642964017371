@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: transparent;
}


@import url('https://fonts.googleapis.com/css2?family=Anton&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,500&family=Syne:wght@400;500;600;700;800&display=swap');

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}


.dark {
  background-color: black;
}


.blur-load {
  filter: blur(20px);
  animation: clearImage 1s ease forwards;
  animation-delay: 2000ms;
}

.art {
  filter: blur(10px);
  animation: clearImage 0.5s ease forwards;
}

@keyframes clearImage {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0);
  }
}
